<!-- 每日汇总统计 By阿旭  -->
<template>
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-select
          class="select-width"
          size="medium"
          v-model="isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker
          v-model="times"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryFormInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo">重置</el-button>
        <el-button type="primary" size="small" @click="exportFormInfo" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
      </div>
    </div>

    <div class="content_left_content">
      <table>
        <tr>
          <td>日期</td>
          <td>当日接收人数</td>
          <td>当日转出人数</td>
          <td>当日在管人数</td>
          <td>抗体检测次数</td>
          <td>抗体出结果次数</td>
          <td>核酸检测次数</td>
          <td>核酸出结果次数</td>
          <td>核酸阴性次数</td>
          <td>核酸阳性次数</td>
          <td>体温检测次数</td>
        </tr>
        <tr v-for="(i,v) in censusData" :key="v">
          <td>{{i.dateStr}}</td>
          <td>{{i.entryPeopleCount}}</td>
          <td>{{i.leavePeopleCount}}</td>
          <td>{{ i.todayCount || '0' }}</td>
          <td>{{ i.antibodyCheckCount || '0' }}</td>
          <td>{{ i.antibodyResultCount || '0' }}</td>
          <td>{{ i.covidCheckCount || '0' }}</td>
          <td>{{ i.covidResultCount || '0' }}</td>
          <td>{{ i.covidNegativeCount || '0' }}</td>
          <td>{{ i.covidPositiveCount || '0' }}</td>
          <td>{{ i.tempCount || '0' }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { exportStatisticCurrentDay, statisticCurrentDay } from "../../api/StatisticalAnalysis/dailySummaryStatistics";
import moment from "moment";
import { mapState } from 'vuex'
import { importFile, messageBox } from '../../utils/utils'

export default {
  data() {
    return {
      times: "",
      censusData: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      isolationPointId: "",
      fullscreenLoading: false
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,
    }),
  },

  created() {
    this.initTime()
  },

  methods: {
    initTime() {
      let day01 = moment().subtract(7, "days").format("YYYY-MM-DD");
      let day02 = moment().subtract(0, "days").format("YYYY-MM-DD");
      this.times = [day01, day02];
      this.getCensusData();
    },

    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode
      };

      if (this.isolationPointId) params.isolationPointId = this.isolationPointId;
      if (this.times && this.times.length == 2) {
        params.timeMin = this.times[0];
        params.timeMax = this.times[1];
      }
      return params;
    },

    getCensusData() {
      let params = this.setParamsInfo();
      statisticCurrentDay({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.censusData = data.reverse();
        } else {
          this.$message.error(msg);
        }
      })
    },

    queryFormInfo() {
      this.getCensusData();
    },

    resetQueryInfo() {
      this.isolationPointId = "";
      this.initTime();
    },

    exportFormInfo() {
      let params = this.setParamsInfo();
      messageBox(() => {
        this.fullscreenLoading = true;
        exportStatisticCurrentDay({params}).then(res => {
          this.fullscreenLoading = false;
          console.log(111111,res);
          importFile(res.data, '每日统计数据')
        })
      }, "warning", '是否确认导出每日统计数据？')
    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/condition-filtrate.scss";

.main {
  width: 100%;
  height: 100%;
}

tr:nth-child(1) {
  td {
    background: #f5f5f5;
  }
}
td {
  flex: 1;
  height: 45px;
  opacity: 1;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;
}
table {
  border-collapse: collapse;
  width: 96%;
}

.content_left_content {
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
